import {theme} from '@styles/theme';
import styled from 'styled-components';

export const SliderWrapper = styled.div`
  position: relative;
  display: flex;
  z-index: 10;
  overflow-x: hidden !important;
`;

export const SlideWrapper = styled.div`
  display: flex;
  margin-bottom: 50px;
  width: 100%;

  @media (max-width: 440px) {
    margin-bottom: 40px;
  }
`;

export const ControlsWrapper = styled.div`
  position: absolute;
  display: flex;
  bottom: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 5px;
  z-index: 40;
`;

export const DotButton = styled.div<{$isActive: boolean}>`
  border: none;
  width: 6px;
  height: 6px;
  background: ${p =>
    p.$isActive ? `${theme.colors.brandGreenV2}` : `${theme.colors.lightGrayV2}`};
  border-radius: 50%;
  cursor: pointer;
`;
