/* eslint-disable react-hooks/exhaustive-deps */
import Button from '@components/atoms/Button';
import {theme} from '@styles/theme';
import styled from 'styled-components';

export const SliderWrapper = styled.div<{wrapperBG: string; withoutPadding: boolean}>`
  background-color: ${({wrapperBG}) =>
    wrapperBG ? `${wrapperBG}` : `${theme.colors.white}`};
  padding: ${({withoutPadding}) => (withoutPadding ? '0' : '80px 0')};
  @media (max-width: 820px) {
    padding: ${({withoutPadding}) => (withoutPadding ? '0' : '50px 0')};
  }
`;

export const SliderContainer = styled.div`
  width: 100%;
  position: relative;
  overflow-x: hidden !important;
  display: flex;
  flex-direction: column;
`;

export const SlideWrapper = styled.div``;

export const Dots = styled.div`
  display: none;
  @media (max-width: 820px) {
    display: block;
  }
`;

export const SliderArrows = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 50px;
  @media (max-width: 820px) {
    display: none;
  }
`;

export const CardsWrapper = styled.div``;

export const CardsContainer = styled.div<{$centered: boolean}>`
  display: flex;
  margin-top: 50px;
  justify-content: ${({$centered}) => ($centered ? 'center' : 'flex-start')};
  @media (max-width: 620px) {
    margin-top: 30px;
  }
`;

export const CardsWrapperBtn = styled(Button)`
  display: none;
  @media (max-width: 490px) {
    display: block;
    margin-top: 30px;
    width: 100%;
  }
`;
