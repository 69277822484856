import styled from 'styled-components';
import Button from './Button';
import {theme} from '@styles/theme';

export const StyledButton = styled(Button)<{disabled?: boolean; style: any}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 18px;
  cursor: ${p => (p.disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    background-color: ${theme.colors.greenBG};
  }

  > * {
    user-select: none;
    ${p => p.disabled && theme.filters.gray};
  }
`;
