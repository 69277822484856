import {theme} from '@styles/theme';
import styled from 'styled-components';

export const BlockTitleWithBtnWrapper = styled.div<{mediaQHideBtn: number | undefined}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  ${({mediaQHideBtn}) =>
    mediaQHideBtn?.toString.length !== 0
      ? `
    @media (max-width: ${mediaQHideBtn}px) {
      & button {
        display: none !important;
      }
    }
  `
      : ''}
`;

export const Title = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  & h2 {
    font-family: ${theme.fonts.headline};
    font-weight: 400;
    font-size: 36px;
    line-height: 43px;
    letter-spacing: -0.25px;
    color: ${theme.colors.darkText};
    @media (max-width: 820px) {
      font-size: 24px;
      line-height: 29px;
    }
    @media (max-width: 490px) {
      font-size: 20px;
      line-height: 24px;
    }
  }
  & p {
    color: ${theme.colors.gray};
    font-size: 18px;
    font-weight: 400;
    @media (max-width: 820px) {
      font-size: 16px;
    }
    @media (max-width: 490px) {
      font-size: 15px;
    }
  }
`;
