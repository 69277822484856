import {useKeenSlider} from 'keen-slider/react';
import {useMemo} from 'react';
import {
  ControlsWrapper,
  DotButton,
  SliderWrapper,
  SlideWrapper,
} from './DotsForSlider.styles';

const DotsForSlider = ({
  currentSlide,
  dotsAmount,
  currentInstance,
  slidesCount,
}: {
  currentSlide: number;
  dotsAmount?: Array<number> | null;
  currentInstance: any;
  slidesCount?: number | null;
}) => {
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>();

  const dots = useMemo(() => {
    if (!slidesCount) {
      return [];
    }
    const dotsArr = [...Array(slidesCount).keys()];
    return dotsArr;
  }, [slidesCount, currentSlide]);

  return (
    <SliderWrapper ref={sliderRef} className="keen-slider">
      <SlideWrapper />
      <ControlsWrapper>
        {dotsAmount
          ? dotsAmount.map((item, idx) => {
              return (
                <DotButton
                  key={item}
                  onClick={() => {
                    currentInstance?.moveToIdx(item);
                  }}
                  $isActive={currentSlide === item}
                />
              );
            })
          : dots.map((item, idx) => {
              return (
                <DotButton
                  key={item}
                  onClick={() => {
                    currentInstance?.moveToIdx(item);
                  }}
                  $isActive={currentSlide === item}
                />
              );
            })}
      </ControlsWrapper>
    </SliderWrapper>
  );
};

export default DotsForSlider;
