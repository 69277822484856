import {FC} from 'react';
import {BlockTitleWithBtnWrapper, Title} from './BlockTitleWithBtn.styles';
import Button from './Button';

interface BlockTitleWithBtnProps {
  title: string;
  subTitle?: string;
  btnText?: string;
  btnOnClick?: () => void;
  style?: Record<string, any>;
  mediaQHideBtn?: number;
  btnLink?: string;
  openLinkBlank?: boolean;
}

const BlockTitleWithBtn: FC<BlockTitleWithBtnProps> = ({
  title,
  subTitle,
  btnText,
  btnOnClick,
  mediaQHideBtn,
  btnLink,
  style,
  openLinkBlank = false,
}) => {
  return (
    <BlockTitleWithBtnWrapper style={style} mediaQHideBtn={mediaQHideBtn}>
      <Title>
        <h2>{title}</h2>
        {subTitle && <p>{subTitle}</p>}
      </Title>
      {!!btnText && (
        <Button
          title={btnText}
          onClick={btnOnClick}
          fontWeight={500}
          style={{padding: '10px 18px'}}
          link={btnLink}
          openLinkBlank={openLinkBlank}
        />
      )}
    </BlockTitleWithBtnWrapper>
  );
};

export default BlockTitleWithBtn;
