/* eslint-disable react-hooks/exhaustive-deps */
import ArrowButton from '@components/atoms/ArrowButton';
import BlockTitleWithBtn from '@components/atoms/BlockTitleWithBtn';
import Button from '@components/atoms/Button';
import DotsForSlider from '@components/molecules/DotsForSlider';
import {useWindowDimensions} from '@hooks';
import {GlobalContainer} from '@styles/pages/_app.styles';
import {getSliderBreakpointsByType} from '@utils/mapper';
import {KeenSliderInstance, KeenSliderOptions} from 'keen-slider';
import {useKeenSlider} from 'keen-slider/react';
import {FC, useEffect, useLayoutEffect, useState} from 'react';
import {
  CardsContainer,
  CardsWrapper,
  Dots,
  SliderArrows,
  SliderContainer,
  SliderWrapper,
  CardsWrapperBtn,
} from './Slider.styles';
import {useIsomorphicLayoutEffect} from '@hooks/useIsomorphicLayoutEffect';

interface SliderProps {
  slidesPerView: number;
  slidesLength: number;
  title?: string;
  subTitle?: string;
  btnTitleText?: string;
  titleRoute?: string;
  wrapperBG?: string;
  type?: string;
  mediaQHideBtn?: number;
  style?: Record<string, any>;
  openLinkBlank?: boolean;
  withoutPadding?: boolean;
  btnOnClick?: () => void;
  nodeRef?: React.MutableRefObject<any>;
  options?: KeenSliderOptions;
}

const Slider: FC<SliderProps> = ({
  slidesPerView,
  title,
  subTitle,
  titleRoute,
  btnTitleText,
  slidesLength,
  wrapperBG,
  children,
  type,
  mediaQHideBtn = 490,
  style,
  openLinkBlank = false,
  withoutPadding,
  btnOnClick,
  nodeRef,
  options = {},
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentSlideIsFirst, setCurrentSlideIsFirst] = useState(true);
  const [currentSlideIsLast, setCurrentSlideIsLast] = useState(false);
  const [dotsAmount, setDotsAmount] = useState<Array<number>>([]);

  const {width} = useWindowDimensions();

  const sliderOptions: KeenSliderOptions = {
    initial: 0,
    loop: false,
    slides: {
      perView: slidesPerView,
      spacing: 30,
    },
    slideChanged(slider: KeenSliderInstance) {
      setCurrentSlide(slider.track.details.rel);
      setCurrentSlideIsFirst(
        !slider.options.loop && slider.track.details.abs === slider.track.details.minIdx,
      );
      setCurrentSlideIsLast(
        !slider.options.loop && slider.track.details.abs === slider.track.details.maxIdx,
      );
    },
    breakpoints: {...getSliderBreakpointsByType(type ?? '')},
    ...options,
  };

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(sliderOptions);

  const getPerView = () => {
    const dots = instanceRef.current?.track?.details?.slides
      ? instanceRef.current?.track?.details?.slides?.length
      : 1;
    if (width! <= 820 && width! >= 620) {
      setDotsAmount([...Array(dots - 1).keys()]);
    }
    if (width! <= 620) {
      setDotsAmount([...Array(dots).keys()]);
    }
  };

  useEffect(() => {
    getPerView();
  }, [width]);

  useIsomorphicLayoutEffect(() => {
    if (instanceRef.current) {
      instanceRef.current.update(sliderOptions);
    }
  }, [children, instanceRef]);

  return (
    <SliderWrapper
      ref={nodeRef}
      wrapperBG={wrapperBG!}
      withoutPadding={withoutPadding!}
      style={style}>
      <GlobalContainer>
        <SliderContainer ref={sliderRef} className="keen-slider">
          {title && (
            <BlockTitleWithBtn
              title={title}
              subTitle={subTitle}
              btnText={btnTitleText}
              btnLink={titleRoute ? titleRoute : ''}
              btnOnClick={btnOnClick}
              mediaQHideBtn={mediaQHideBtn}
              openLinkBlank={openLinkBlank}
            />
          )}
          <CardsWrapper>
            <CardsContainer $centered={slidesLength < slidesPerView}>
              {children}
            </CardsContainer>
            {btnTitleText && (
              <CardsWrapperBtn
                title={btnTitleText}
                fontWeight={500}
                link={titleRoute ? titleRoute : ''}
                openLinkBlank={openLinkBlank}
                onClick={btnOnClick}
              />
            )}
          </CardsWrapper>
          {slidesLength > slidesPerView && slidesLength <= 50 && (
            <SliderArrows>
              <ArrowButton
                type="left"
                onClick={() => instanceRef.current?.prev()}
                disabled={currentSlideIsFirst}
              />
              <ArrowButton
                type="right"
                onClick={() => instanceRef.current?.next()}
                disabled={currentSlideIsLast}
              />
            </SliderArrows>
          )}
          {instanceRef.current && slidesLength !== 0 && slidesLength <= 50 && (
            <Dots>
              <DotsForSlider
                currentInstance={instanceRef.current}
                currentSlide={currentSlide}
                dotsAmount={dotsAmount}
              />
            </Dots>
          )}
        </SliderContainer>
      </GlobalContainer>
    </SliderWrapper>
  );
};

export default Slider;
