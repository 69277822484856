import Image from 'next/legacy/image';
import React, {FC} from 'react';
import {StyledButton} from './ArrowButton.styles';

interface ArrowButtonProps {
  type: 'left' | 'right';
  onClick?: (e: any) => void;
  disabled?: boolean;
  style?: React.CSSProperties;
}

const ArrowLeft = (
  <Image src="/assets/icons/arrowLeft.svg" width={11} height={11} alt="left arrow" />
);

const ArrowRight = (
  <Image src="/assets/icons/arrowRight.svg" width={11} height={11} alt="right arrow" />
);

const ArrowButton: FC<ArrowButtonProps> = ({type, onClick, disabled, style}) => {
  return (
    <StyledButton
      title={type === 'left' ? ArrowLeft : ArrowRight}
      onClick={onClick}
      disabled={disabled}
      style={{...style}}
      testID={type === 'left' ? 'left-control' : 'right-control'}
    />
  );
};

export default ArrowButton;
